import React from 'react';
import LayoutBasic from '../components/layout/LayoutBasic';

export default function Career() {  

  const tasks = [
    "gyártástámogató és termeléskövető rendszerek fejlesztésében való részvétel .NET környezetben",
    "új technológiák felkutatása és alkalmazása a gyakorlatban",
  ];

  const requirements = [
    "nappali tagozatú, felsőfokú, szakirányú végzettség (programtervező vagy mérnök informatikus szakon, BSc vagy MSc képzésben)",
    "webes fejlesztés (asp.net webforms, mvc) és C# nyelv ismerete",
    "adatbázis programozás (Transact-SQL) és SQL nyelv ismerete",
    "dokumentáció-olvasási szintű angol nyelvismeret",
    "személyes felelősségvállalás",
    "tanulási vágy",
    "részfeladatokban önálló döntési képesség",
    "precíz munkavégzés",
    "a projekten dolgozó többi munkatárssal való összehangolt munkavégzés",
    "pozitív tulajdonságok: kreatív, önálló, precíz, lelkiismeretes, igényes, rugalmas, minőségre törekvő",
  ];

  const technologies = ["MS SQL", "ASP.NET", "MVC", "C#", "Visual Studio", "CSS", "HTML", "Javascript", "jQuery", "Angular"];

  const offers = [
    "legmodernebb fejlesztő eszközök és technológiák megismerése",
    "folyamatos szakmai fejlődés, tanulási lehetőség tapasztalt kollégák mellett",
    "érdekes feladatok",
    "kellemes munkakörnyezet, fiatal, dinamikus csapatban való részvétel",
    "versenyképes jövedelem",
    "utazási támogatás, cafeteria",
  ];

  return (
    <LayoutBasic>
    <section className="flex justify-center p-10">
      <div className="bg-gray-100 border-gray-200 border rounded-lg p-10">
        <div className="text-center pb-4">
          <h1 className="text-5xl font-bold">Dolgozz nálunk</h1>
        </div>
        <div>
          <div className="text-2xl text-center text-gray-700 pb-8">
            Az ADB-Pro Informatikai Kft. <strong>SZOFTVERFEJLESZTŐ</strong> munkatársat keres.
          </div>
          <div className="pb-4">
            <h4 className="text-xl font-bold">FŐBB FELADATOK, MUNKÁK</h4>
            <div className="pl-8">
              <ul>
                {tasks.map(t => (
                  <li><span className="square bg-gray-700"></span>{t}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="pb-4">
            <h4 className="text-xl font-bold">AZ ÁLLÁSHOZ TARTOZÓ ELVÁRÁSOK</h4>
            <div className="pl-8">
              <ul className="square-list">
                {requirements.map(r => (
                  <li><span className="square bg-gray-700"></span>{r}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="pb-4">
            <h4 className="text-xl font-bold">ALKALMAZOTT TECHNOLÓGIÁK</h4>
            <div className="flex flex-wrap">
              {technologies.map(t => (
                <span className="px-2 py-1 mx-1 my-2 bg-gray-700 text-gray-200 rounded-lg">{t}</span>
              ))}
            </div>
          </div>
          <div className="pb-4">
            <h4 className="text-xl font-bold">AMIT NYÚJTANI TUDUNK</h4>
            <div className="pl-8">
              <ul>
              {offers.map(o => (
                  <li><span className="square bg-gray-700"></span>{o}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="pb-4">
            <div>
              <ul>
                <li>
                  <strong>Munkavégzés helye:</strong> Jászberény
                </li>
                <li>
                  <strong>Munkába állás legkorábbi kezdete:</strong> megegyezés szerint
                </li>
                <li>
                  <strong>Fizetés:</strong> megegyezés szerint
                </li>
                <li>
                  <strong>Próbaidő hossza:</strong> 3 hónap
                </li>
                <li>
                  <strong>Egyéb juttatások:</strong> éves bónusz / mozgóbér, mobiltelefon, cafeteria
                </li>
                <li>
                  Angol és magyar nyelvű szakmai önéletrajzot és motivációs levelet kérjük a{' '}
                  <a href="mailto:mail@adb-pro.hu">mail@adb-pro.hu</a> email címre küldeni!
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-8 mb-4 text-center text-xl">
            <strong>Pályakezdők jelentkezését is várjuk!</strong>
          </div>
        </div>
      </div>
    </section>
  </LayoutBasic>
  );
}

