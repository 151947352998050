import React from 'react';
import { Link } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';

import Helmet from '../Helmet';
import SvgAdb from '../../svg/SvgAdb';
import LeftIcon from '../../icons/LeftIcon';

// eslint-disable-next-line react/prop-types
const LayoutBasic = ({ children }) => {
  const intl = useIntl();
  return (
    <>
      <Helmet lang={intl.locale} />
      <div className="sticky top-0 bg-white shadow">
        <div className="flex items-center">
          <Link className="p-4" to="/" title="Vissza">
            <LeftIcon />
          </Link>
          <div className="" style={{ width: '16rem' }}>
            <SvgAdb />
          </div>
        </div>
      </div>
      <main>{children}</main>
    </>
  );
};

export default LayoutBasic;
